var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"myhome"},[_c('Nav'),_c('div',{staticClass:"myNftCon"},[_c('div',{staticClass:"con0"},[_c('p',[_c('b',[_vm._v(_vm._s(_vm.lang('mynft')))])])]),_c('div',{staticClass:"con1"},[_c('div',{staticClass:"conNum"},[_c('div',{staticClass:"a"},[_c('p',[_c('b',[_vm._v(_vm._s(_vm.nftNumObj.honorNums/1+_vm.nftNumObj.benefitNums/1))])]),_c('p',{staticClass:"n"},[_vm._v(_vm._s(_vm.lang('chiyouNFTzongshu')))])]),_c('div',{staticClass:"a"},[_c('p',[_c('b',[_vm._v(_vm._s(_vm.nftNumObj.honorNums))])]),_c('p',{staticClass:"n"},[_vm._v(_vm._s(_vm.lang('honorNFTshu')))])]),_c('div',{staticClass:"a"},[_c('p',[_c('b',[_vm._v(_vm._s(_vm.nftNumObj.benefitNums))])]),_c('p',{staticClass:"n"},[_vm._v(_vm._s(_vm.lang('BenefitNFTshu')))])])])]),_c('div',{staticClass:"con2"},[_c('div',{staticClass:"conList"},[_vm._v(_vm._s(_vm.lang('wodenftliebiao')))]),_c('div',{staticClass:"btn"},[_c('van-tabs',{model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('van-tab',{attrs:{"title":_vm.lang('quanbu'),"name":"a"}},[_c('van-list',{attrs:{"finished":_vm.finished,"immediate-check":false},on:{"load":function($event){return _vm.onLoad()}},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('div',{staticClass:"con3"},[(_vm.getList.length==0)?_c('div',{staticClass:"cont"},[_c('div',{staticClass:"content1"},[_c('p',[_c('img',{attrs:{"src":require("../../assets/img/nonft.png"),"alt":""}})]),_c('p',[_vm._v(_vm._s(_vm.lang('haimeiyoutupian')))])])]):_vm._l((_vm.getList),function(item){return _c('div',{key:item.id,staticClass:"cont"},[_c('div',{staticClass:"content",style:({
                      backgroundImage:item.level == 1? ("url(" + (require('../../assets/img/jiezhi.png')) + ")")
                        : ("url(" + (require('../../assets/img/xingxing.png')) + ")"),
                  })},[(item.level==1)?_c('div',{staticClass:"Img"},[_vm._v("Honor")]):_vm._e(),(item.level==2)?_c('div',{staticClass:"ImgB Img"},[_vm._v("Benefit")]):_vm._e()]),_c('div',{staticClass:"id"},[_vm._v("#"+_vm._s(item.tokenId))])])}),_vm._l(((_vm.row-_vm.getList.length%_vm.row)),function(item){return _c('div',{key:item.id,staticClass:"list"})})],2)])],1),_c('van-tab',{attrs:{"title":"Honor","name":"b"}},[_c('div',{staticClass:"con3"},[(_vm.getListHonor.length==0)?_c('div',{staticClass:"cont"},[_c('div',{staticClass:"content1"},[_c('p',[_c('img',{attrs:{"src":require("../../assets/img/nonft.png"),"alt":""}})]),_c('p',[_vm._v(_vm._s(_vm.lang('haimeiyoutupian')))])])]):_vm._l((_vm.getListHonor),function(item){return _c('div',{key:item.id,staticClass:"cont"},[_c('div',{staticClass:"content",style:({
                    backgroundImage:item.level == 1? ("url(" + (require('../../assets/img/jiezhi.png')) + ")")
                      : ("url(" + (require('../../assets/img/xingxing.png')) + ")"),
                })},[(item.level==1)?_c('div',{staticClass:"Img"},[_vm._v("Honor")]):_vm._e(),(item.level==2)?_c('div',{staticClass:"ImgB Img"},[_vm._v("Benefit")]):_vm._e()]),_c('div',{staticClass:"id"},[_vm._v("#"+_vm._s(item.tokenId))])])}),_vm._l(((_vm.row-_vm.getListHonor.length%_vm.row)),function(item){return _c('div',{key:item.id,staticClass:"list"})})],2)]),_c('van-tab',{attrs:{"title":"Benefit","name":"c"}},[_c('div',{staticClass:"con3"},[(_vm.getListBenefit.length==0)?_c('div',{staticClass:"cont"},[_c('div',{staticClass:"content1"},[_c('p',[_c('img',{attrs:{"src":require("../../assets/img/nonft.png"),"alt":""}})]),_c('p',[_vm._v(_vm._s(_vm.lang('haimeiyoutupian')))])])]):_vm._l((_vm.getListBenefit),function(item){return _c('div',{key:item.id,staticClass:"cont"},[_c('div',{staticClass:"content",style:({
                    backgroundImage:item.level == 1? ("url(" + (require('../../assets/img/jiezhi.png')) + ")")
                      : ("url(" + (require('../../assets/img/xingxing.png')) + ")"),
                })},[(item.level==1)?_c('div',{staticClass:"Img"},[_vm._v("Honor")]):_vm._e(),(item.level==2)?_c('div',{staticClass:"ImgB Img"},[_vm._v("Benefit")]):_vm._e()]),_c('div',{staticClass:"id"},[_vm._v("#"+_vm._s(item.tokenId))])])}),_vm._l(((_vm.row-_vm.getListBenefit.length%_vm.row)),function(item){return _c('div',{key:item.id,staticClass:"list"})})],2)]),_c('van-tab',{attrs:{"title":"Hammer","name":"d"}})],1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }