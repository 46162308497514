<template>
  <div class="myhome">
    <Nav></Nav>
    <div class="myNftCon">
        <div class="con0">
            <p><b>{{lang('mynft')}}</b></p>
        </div>
      <div class="con1">     
        <div class="conNum">
          <div class="a">
            <p><b>{{nftNumObj.honorNums/1+nftNumObj.benefitNums/1}}</b></p>
            <p class="n">{{lang('chiyouNFTzongshu')}}</p>
          </div>
          <div class="a">
            <p><b>{{nftNumObj.honorNums}}</b></p>
            <p class="n">{{lang('honorNFTshu')}}</p>
          </div>
          <div class="a">
            <p><b>{{nftNumObj.benefitNums}}</b></p>
            <p class="n">{{lang('BenefitNFTshu')}}</p>
          </div>
        </div>
      </div>
      <div class="con2">
        <div class="conList">{{lang('wodenftliebiao')}}</div>
        <div class="btn">
          <van-tabs v-model="active">
            <van-tab :title="lang('quanbu')" name="a" >  
              <!-- finished-text="没有更多了" -->
              <van-list
                  v-model="loading"
                  :finished="finished"                
                  @load="onLoad()"
                  :immediate-check="false" >
                <div class="con3">
                    <div class="cont" v-if="getList.length==0">
                        <div class="content1">
                          <p><img src="../../assets/img/nonft.png" alt=""></p>
                          <p>{{lang('haimeiyoutupian')}}</p>
                        </div>          
                    </div>
                    <div class="cont" v-else v-for="item in getList" :key="item.id" >
                        <div class="content" :style="{
                        backgroundImage:item.level == 1? `url(${require('../../assets/img/jiezhi.png')})`
                          : `url(${require('../../assets/img/xingxing.png')})`,
                    }">
                            <div class="Img" v-if="item.level==1">Honor</div>
                            <div class="ImgB Img" v-if="item.level==2">Benefit</div>
                        </div>
                        <div class="id">#{{item.tokenId}}</div>
                    </div>
                
                  <div class="list" v-for="item in (row-getList.length%row)" :key="item.id" ></div>
                    
                </div>
              </van-list>
            </van-tab>
            <van-tab title="Honor" name="b" >
              <div class="con3">
                  <div class="cont" v-if="getListHonor.length==0">
                      <div class="content1">
                        <p><img src="../../assets/img/nonft.png" alt=""></p>
                        <p>{{lang('haimeiyoutupian')}}</p>
                      </div>          
                  </div>
                  <div class="cont" v-else v-for="item in getListHonor" :key="item.id" >
                      <div class="content" :style="{
                      backgroundImage:item.level == 1? `url(${require('../../assets/img/jiezhi.png')})`
                        : `url(${require('../../assets/img/xingxing.png')})`,
                  }">
                          <div class="Img" v-if="item.level==1">Honor</div>
                          <div class="ImgB Img" v-if="item.level==2">Benefit</div>
                      </div>
                      <div class="id">#{{item.tokenId}}</div>
                  </div>
              
                <div class="list" v-for="item in (row-getListHonor.length%row)" :key="item.id" ></div>
                  
              </div>
            </van-tab>
            <van-tab title="Benefit" name="c" >
              <div class="con3">
                  <div class="cont" v-if="getListBenefit.length==0">
                      <div class="content1">
                        <p><img src="../../assets/img/nonft.png" alt=""></p>
                        <p>{{lang('haimeiyoutupian')}}</p>
                      </div>          
                  </div>
                  <div class="cont" v-else v-for="item in getListBenefit" :key="item.id" >
                      <div class="content" :style="{
                      backgroundImage:item.level == 1? `url(${require('../../assets/img/jiezhi.png')})`
                        : `url(${require('../../assets/img/xingxing.png')})`,
                  }">
                          <div class="Img" v-if="item.level==1">Honor</div>
                          <div class="ImgB Img" v-if="item.level==2">Benefit</div>
                      </div>
                      <div class="id">#{{item.tokenId}}</div>
                  </div>
              
                <div class="list" v-for="item in (row-getListBenefit.length%row)" :key="item.id" ></div>
                  
              </div>
            </van-tab>
            <van-tab title="Hammer" name="d" >
              <!-- <div class="con3">
                  <div class="cont" v-if="getListBenefit.length==0">
                      <div class="content1">
                        <p><img src="../../assets/img/nonft.png" alt=""></p>
                        <p>{{lang('haimeiyoutupian')}}</p>
                      </div>          
                  </div>
                  <div class="cont" v-else v-for="item in getListBenefit" :key="item.id" >
                      <div class="content" :style="{
                      backgroundImage:item.level == 1? `url(${require('../../assets/img/jiezhi.png')})`
                        : `url(${require('../../assets/img/xingxing.png')})`,
                  }">
                          <div class="Img" v-if="item.level==1">Honor</div>
                          <div class="ImgB Img" v-if="item.level==2">Benefit</div>
                      </div>
                      <div class="id">#{{item.tokenId}}</div>
                  </div>
              
                <div class="list" v-for="item in (row-getListBenefit.length%row)" :key="item.id" ></div>
                  
              </div> -->
            </van-tab>
          </van-tabs>
        </div>
      </div>
         
    </div>
  </div>
</template>
<script>
import Nav from "../../components/Nav/Index";
import { lang } from "../../tools";
import * as metaMaskTools from "../../tools/chain";
import { mixin1, mixin2, mixin3 } from "../../tools/mixins";

export default {
  name: "myNft",
  components: { Nav },
  mixins: [mixin1, mixin2, mixin3],
  created() {},
  mounted() {},
  computed: {
      account() {
        return this.$store.state.accounts[0];
      },
    },
  data() {
    return {
      getList : [],
      getListHonor:[],
      getListBenefit:[],
		  row: 5,
      setTime: null,
      setTimeOnly: null,
      active: 'a',
      getListPageNum: 20, //上拉加载 每页数量
      loading: false, //上拉加载 当前加载状态
      finished: false, //上拉加载 全部加载状态
      currentPage: 1, //当前页码
      nftNumObj:{
        honorNums:0,
        benefitNums:0,
      },
      
      getNFTNumTotal:0,
      total:0
    };
  },
  methods: {
    initPage() {
      this.getNFTNum()
      this.onLoad( this.account,
          this.currentPage,
          this.getListPageNum)
      this.HonNum(this.account,
          this.currentPage,
          100000)
      this.BenNum(this.account,
          this.currentPage,
          100000)
    },
    initData() {},
     onRefresh(accounts) {
      window.location.reload();
      this.initPage();
    },
    async onLoad(account,page,size) {
      var NFTNum = [];
      try {
        NFTNum = await metaMaskTools.getnftList(
          this.account,
          this.currentPage,
          this.getListPageNum
        );
      } catch (e) {}
      var List=NFTNum.list
      if (this.currentPage <= 1) {
        this.getList = [];
        for (var i in List) {
          this.getList.push(List[i]);
        }
      } else {
        for (var i in List) {
          this.getList.push(List[i]);
        }
      }
      this.currentPage++;
      this.loading = false;
      if (!List || List.length <= 0) {
        this.finished = true;
      }
      console.log(this.getList,2222);
    },
    async HonNum(account,page,size) {
      var H=[]
       try {      
       H = await metaMaskTools.getnftList(
          this.account,
          this.currentPage,
          10000
        );
      } catch (e) {}
        var Hon=H.list
        console.log(Hon,'Hon');
        this.getListHonor = Hon.filter((item) => {
          return item.level === "1";
        });
        console.log(this.getListHonor,'this.getListHonor');
    },
    async BenNum(account,page,size) {
      var B=[]
       try {      
       B = await metaMaskTools.getnftList(
          this.account,
          this.currentPage,
          10000
        );
      } catch (e) {}
        var Ben=B.list
        console.log(Ben,'Ben');
        this.getListBenefit = Ben.filter((item) => {
          return item.level === "2";
        });
        console.log(this.getListBenefit,'this.getListBenefit');
    },
    async getNFTNum() {
       var res=await metaMaskTools.getNFTNum(this.account);
       this.nftNumObj=res
    },
    lang(key){
        return lang(key);
    },
   
  },
};
</script>

<style scoped lang="less">
@media (min-width: 769px) {
  .myNftCon {
  box-sizing: border-box;
  width: 100%;
  padding: 110px 40px 0 240px;
  .con0 {
    z-index: 40;
    position: fixed;
    margin-top: 80px;
    width: 100%;
    p {
      font-size: 15px;
      color: #fff;
    }
   }
  .con1 {
     z-index: 40;
    margin-top: 140px;
    width: 100%;
    p {
      font-size: 15px;
      color: #fff;
    }
    .conNum {
      display: flex;
      align-items: center;
      border-radius: 10px;
      width: 100%;
      height: 80px;
      background: linear-gradient(#0077c5, #0045bb);
      margin-top: 20px;
      .a {
        text-align: center;
        width: 33.3%;
        border-right: 1px solid #ccc;
        .n {
          margin-top: 8px;
        }
      }
      .a:nth-of-type(3) {
          border-right: 0;
      }
    }
  }
  .con2 {
        position: relative;
        margin-top: 30px;
        width: 100%;
        color: #fff;
        z-index: 1;
        .conList {
          text-align-last: left;
        }
    /deep/ .van-tabs__wrap {
        position: absolute;
        right: 0;
        top: -35px;
        width: 360px;
        background:rgba(#000,.4);
        height: 40px;
        line-height: 40px;
        border-radius: 10px;
    }
    .con3 {
        position: absolute;
        top: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content:space-between;
        justify-items: center;
        text-align: justify;
        margin-top: 20px;
        width: 100%;
      .cont {
          content: '';
          width: 18%;
          height: 215px; 
          background-color: rgba(#0F111F,.5);
          border-radius: 10px;
          padding: 7.5px 9.5px 0;
          margin-bottom: 10px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          .content {
              position: relative;
              width: 100%;
              height: 190px;
              background: url('../../assets/img/jiezhi.png') no-repeat;
              background-size: 100% 100%;
              .Img {
                  position: absolute;
                  top: 5px;
                  right: 5px;
                  width: 56px;
                  height: 20px;
                  color: #fff;
                  font-size: 12px;
                  text-align: center;
                  line-height: 20px;
                  background: url('../../assets/img/honor.png') no-repeat;
                  background-size: 100% 100%;
              }
              .ImgB {
                  background: url('../../assets/img/bene.png') no-repeat;
                  background-size: 100% 100%;
              }
          }
          .content1 {
              position: relative;
              width: 100%;
              height: 185px;
              background-color: rgba(#0F111F,.5);
              display: flex;
              flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  color: #fff;
                  font-size: 12px;
              img {
         
                  width: 35px;
                  height: 35px;
                  
              }
          }
          .id {
              color: #fff;
          }
      }
  }
    /deep/ .van-tab {
      font-size: 12px;
      width: 80px;
      height: 30px;
      line-height: 30px;
      border-radius: 15px;
      text-align: center;
      padding: 0;
      box-sizing: border-box;
      margin-top: 3px;
      color: #fff;
      flex: none;
    }

    /deep/ .van-tab--active {
      background: linear-gradient( #0077C5 , #0045BB);
      border: none;
      color: #fff;
    }

    /deep/ .van-tabs__nav {
      width: 80px;
      height: 36px;
      line-height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto 20px;
      border-radius: 18px;
      background: transparent;
    }
    /deep/ .van-tabs__nav--line {
      padding-bottom: 0;
    }
    /deep/ .van-tabs__line {
      display: none;
    }
  }
  
  .list{
  content: '';
  width: 18%;
  overflow: hidden;
}
}
}

@media (max-width: 768px) {
  .myNftCon {
  box-sizing: border-box;
  width: 100%;
  padding: 0 10px 0 ;
  .con0 {
    margin-top: 20px;
    width: 100%;
    p {
      font-size: 15px;
      color: #fff;
    }
   }
  .con1 {
    margin-top: 20px;
    width: 100%;
    p {
      font-size: 12px;
      color: #fff;
    }
    .conNum {
      display: flex;
      align-items: center;
      border-radius: 10px;
      width: 100%;
      height: 80px;
      background: linear-gradient(#0077c5, #0045bb);
      margin-top: 20px;
      .a {
        text-align: center;
        width: 33.3%;
        border-right: 1px solid #ccc;
        .n {
          margin-top: 8px;
        }
      }
      .a:nth-of-type(3) {
          border-right: 0;
      }
    }
  }
  .con2 {
        position: relative;
        margin-top: 30px;
        width: 100%;
        color: #fff;
        z-index: 1;
        .conList {
          text-align-last: left;
        }
    /deep/ .van-tabs__wrap {
        position: absolute;
        right: 0;
        top: -35px;
        width: 240px;
        background:rgba(#000,.4);
        height: 40px;
        line-height: 40px;
        border-radius: 10px;
    }
    .con3 {
        position: absolute;
       top: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content:space-between;
        justify-items: center;
        text-align: justify;
        margin-top: 20px;
        width: 100%;
      .cont {
          content: '';
          width: 48%;
          height: 215px; 
          background-color: rgba(#0F111F,.5);
          border-radius: 10px;
          padding: 7.5px 7.5px 0;
          margin-bottom: 10px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
          .content {
              position: relative;
              width: 100%;
              height: 185px;
              background: url('../../assets/img/jiezhi.png') no-repeat;
              background-size: 100% 100%;
              .Img {
                  position: absolute;
                  top: 5px;
                  right: 5px;
                  width: 56px;
                  height: 20px;
                  color: #fff;
                  font-size: 12px;
                  text-align: center;
                  line-height: 20px;
                  background: url('../../assets/img/honor.png') no-repeat;
                  background-size: 100% 100%;
              }
              .ImgB {
                  background: url('../../assets/img/bene.png') no-repeat;
                  background-size: 100% 100%;
              }
          }
          .content1 {
              position: relative;
              width: 100%;
              height: 185px;
              background-color: rgba(#0F111F,.5);
              display: flex;
              flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  color: #fff;
                  font-size: 12px;
              img {
         
                  width: 35px;
                  height: 35px;
                  
              }
          }
          .id {
              color: #fff;
          }
      }
  }
    /deep/ .van-tab {
      font-size: 12px;
      width: 60px;
      height: 30px;
      line-height: 30px;
      border-radius: 15px;
      text-align: center;
      padding: 0;
      box-sizing: border-box;
      margin-top: 3px;
      color: #fff;
      flex: none;
    }

    /deep/ .van-tab--active {
      background: linear-gradient( #0077C5 , #0045BB);
      border: none;
      color: #fff;
    }

    /deep/ .van-tabs__nav {
      width: 80px;
      height: 36px;
      line-height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto 20px;
      border-radius: 18px;
      background: transparent;
    }
    /deep/ .van-tabs__nav--line {
      padding-bottom: 0;
    }
    /deep/ .van-tabs__line {
      display: none;
    }
  }
 
  .list{
  content: '';
  width: 48%;
  overflow: hidden;
}
}
}
</style>
