<template>
  <div class="myhome">
      <Nav></Nav>
      <div class="mynodeCon">
          <div class="con1 cursor-pointer" @click="JumpNode">
            <div></div>
            <div>{{lang('fanhui')}}</div>
          </div>
          <div class="con2">
            <p><b>{{lang('xiugaixinxi')}}</b></p>
          </div>
          <div class="con3">
            <!-- v-model="myInfoObj.name"-->
            <van-field
              
              v-model="nodename" 
              error
              required
              :label="lang('jiedianmingcheng')"
              :placeholder="lang('shurujeidianmc')"
            />
             <van-field
             v-model="nodeslogan"
              error
              required
              :label="lang('jiediankouhao')"
              :placeholder="lang('shurujiediankuhao')"
            />
             <van-field
             v-model="nodeAll"
             type="number"
             value="myInfoObj.rate"
              error
              required
              :label="lang('fenpeibili')"
              right-label="lang('fenpeibili')"
              :placeholder="lang('shurubili')"
            >
            <template #button>%</template>
             </van-field>
             <div class="btnw">
                 <div class="btnf cursor-pointer"   @click="cancel">{{lang('quxiao')}}</div>
                 <div class="btnz btnf cursor-pointer"  @click="submit">{{lang('baocunxinxi')}}</div>
             </div>
          </div>
      </div>
  </div>
</template>
<script>
    import Nav from '../../components/Nav/Index'
    import { lang, Toast,checkAdress } from "../../tools";
    import { formatDate } from "../../tools/index";
    import * as metaMaskTools from "../../tools/chain";
    import { mixin1, mixin2, mixin3 } from "../../tools/mixins";
  export default {
    name: 'ModifyInfor',
    components: {Nav},
    mixins: [mixin1, mixin2, mixin3],
    mounted() {
    },
    data() {
          return {
            nodeslogan:'',
            nodeAll:'',
            nodename:'',
            oTime:"",
            time:'',
            getMyList:[],
            myInfoObj:{
              rate:0,
              totalAmount:0,
              voteAmount:0,
              depositAmount:0,
              name:'',
              slogan:'',
            },
          }
      },
      computed: {
        account() {
          return this.$store.state.accounts[0];
        },
      },
      created() {
      },
      beforeDestroy(){clearInterval(this.timer)},
      methods: {
        initPage() {
          this.getCountdown()
          this.getNodeMyInfo()
        },
        initData() {
        },
        JumpNode() {
            this.$router.push('/Mynode');
        },
        cancel() {
            this.$router.push('/Mynode');
        },
        
        onRefresh(accounts) {
            this.initPage()
        },
         async getNodeMyInfo() {
          var res = await metaMaskTools.getNodeMyInfo(this.account);
          this.myInfoObj=res
          this.nodename=res.name
          this.nodeslogan=res.slogan
          this.nodeAll=res.rate
          console.log(res,';res');
        },
        a(){
          if(this.time){
          this.oTime = formatDate(this.time * 1000);
          }
        },
    
        async getCountdown(format,res) {
          var res = await metaMaskTools.getCountdown(this.account);
          this.time=res
          this.timer=setInterval(this.a,1000)     
        },
        async submit() {
         var re = /^[1-9]+[0-9]*]*$/; 
         if(this.nodename.length>30 ||this.nodeslogan.length>50) {
           Toast(this.lang('shuruzishuchaochuxianzhi'))
           return
         }
         if(this.nodename== ''||this.nodeslogan==''||this.nodeAll=='' ) {
           Toast(this.lang('xinxitianxiebueanzheng'))
           return
         }
         if(!re.test(this.nodeAll)) {
           Toast(this.lang('bukeshuruxiaoshu'))
           return
         }
         if(this.nodeAll/1>100 || this.nodeAll/1<50) {
           Toast(this.lang('qingshuruguidingfanweineibili'))
           return
         }
         if(this.oTime=='end') {
              Toast(this.lang('jingxuanjieshu'))
              return
          } else {
              var res=await metaMaskTools.getModifyInfo(this.account,this.nodename,this.nodeslogan,this.nodeAll);
              Toast(this.lang('xiugaichenggong'))
              this.$router.push('/node');
          }
          
        },
        lang(key){
            return lang(key);
        },
        
    },
    beforeDestroy(){clearInterval(this.timer)}
  }

</script>

<style scoped lang="less">
.cursor-pointer{
  cursor: pointer;
}
@media (min-width: 769px) {  .mynodeCon {
    box-sizing: border-box ;
    width: 100%;
    padding: 130px 40px 0 240px;
      .con1 {
          position: fixed;
          z-index: 40;
          width: 80px;
          height: 28px;
          line-height: 25px;
          border-radius: 14px;
          border: 1px solid #fff;
          background-color: #152e55;
          color: #fff;
          padding: 0 10px;        
          div:nth-of-type(1) {
            width: 12px;
            height: 12px;
            background: url('../../assets/img/fenhui.png') no-repeat;
            background-size: 100% 100%;
            color: #fff;
            display: inline-block;
            vertical-align:middle;
            margin-right: 10px;
          }
          div:nth-of-type(2) {
            display: inline-block;
            font-size: 12px;
            vertical-align:middle;
          }
          
      }
      .con2 {
            z-index: 40;
            position: fixed;
            margin-top: 60px;
            color: #fff;
            p:nth-of-type(2) {
              font-size: 12px;
              margin-top: 8px;
            }
      }
      .con3 {
        width: 100%;
        margin-top: 110px;
        height: 242px;
        background-color: rgba(#0F111F,.4);
        border-radius: 10px;
        .van-field {
          background: transparent;
          line-height: 30px;
          z-index: 0 !important;
        }
        /deep/ .van-field__control::placeholder  {
          color: rgba(#fff,.6);
          font-size: 12px;
        }
        /deep/ .van-field__label {
          width: auto;
          color: #fff;
          font-size: 14px;
        }
        .btnw {
            display: flex;
            justify-content: center;
            font-size: 12px;
            margin: 30px auto;
        }
        .btnf {
            width: 75px;
            height: 22.5px;
            // background: linear-gradient(rgba(#0077c5,.4), rgba(#0045bb,.4));
            border-radius: 10px;
            line-height: 22.5px;
            text-align: center;
            color: #0077C5;
            border: 1px solid #0077c5;
        }
        .btnz {
            width: 125px;
            height: 22.5px;
            background: linear-gradient(#0077c5, #0045bb);
            margin-left: 20px;
            border-radius: 10px;
            line-height: 22.5px;
            text-align: center;
            color: #fff;
        }
 
      }
  }}

  @media (max-width: 768px){
    .mynodeCon {
    box-sizing: border-box ;
    width: 100%;
    padding: 10px 10px 0 ;
      .con1 {
          margin-top: 20px;
          text-align: center;
          width: 80px;
          height: 28px;
          line-height: 25px;
          border-radius: 14px;
          border: 1px solid #fff;
          background-color: #152e55;
          color: #fff;
          padding: 0 10px;        
          div:nth-of-type(1) {
            width: 12px;
            height: 12px;
            background: url('../../assets/img/fenhui.png') no-repeat;
            background-size: 100% 100%;
            color: #fff;
            display: inline-block;
            vertical-align:middle;
            margin-right: 10px;
          }
          div:nth-of-type(2) {
            display: inline-block;
            font-size: 12px;
            vertical-align:middle;
          }
          
      }
      .con2 {
            margin-top: 20px;
            color: #fff;
            p:nth-of-type(2) {
              font-size: 12px;
              margin-top: 8px;
            }
      }
      .con3 {
        width: 100%;
        margin-top: 20px;
        height: 282px;
        background-color: rgba(#0F111F,.4);
        border-radius: 10px;
        .van-field {
          background: transparent;
          line-height: 30px;
        }
        /deep/ .van-field__control::placeholder  {
          color: rgba(#fff,.6);
          font-size: 12px;
        }
        /deep/ .van-field__label {
          width: auto;
        }
        .btnw {
            display: flex;
            justify-content: center;
            font-size: 12px;
            margin: 30px auto;
        }
        .btnf {
            width: 75px;
            height: 22.5px;
            // background: linear-gradient(rgba(#0077c5,.4), rgba(#0045bb,.4));
            border-radius: 10px;
            line-height: 22.5px;
            text-align: center;
            color: #0077C5;
            border: 1px solid #0077c5;
        }
        .btnz {
            width: 75px;
            height: 22.5px;
            background: linear-gradient(#0077c5, #0045bb);
            margin-left: 20px;
            border-radius: 10px;
            line-height: 22.5px;
            text-align: center;
            color: #fff;
        }
      }
  }
  }

</style>
